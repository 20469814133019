import React from 'react';

import { Modal, Button } from 'react-bootstrap';

function ModalConfirmCancel({ show, handleSubmit, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="text-center">
        <h4 className="text-danger">Você tem certeza que deseja cancelar?</h4>
        <div className="d-flex justify-content-center mt-4">
          <Button variant="outline-danger" size="sm" onClick={handleSubmit}>
            Sim, cancelar
          </Button>
          <Button
            variant="danger"
            size="sm"
            className="ml-2"
            onClick={handleClose}
          >
            Voltar
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalConfirmCancel;
