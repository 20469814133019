import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Error from './Error';
import Home from './Home';
import Auth from './Auth';
import Checkout from './Checkout';

export default function MainPage() {
  const { id } = useSelector((state) => state.theme);

  return (
    <Switch>
      <Route exact path="/login/:companyId" component={Home} />
      <Route exact path="/auth/:token" component={Auth} />
      <Route
        exact
        path="/checkout/:companyId/:productId"
        component={Checkout}
      />
      <Route exact path="/error" component={Error} />
      {id ? <Redirect to={`/login/${id}`} /> : <Redirect to="/error" />}
    </Switch>
  );
}
