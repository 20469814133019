import React, { useState, useEffect } from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import axios from 'axios';

import { Close } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from './styles';

import Header from '../components/Header';
import ModalCourse from './components/ModalCourse';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

function Browse() {
  const [open, setOpen] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [video, setVideo] = useState(null);
  const [playlists, setPlaylists] = useState([]);
  const [videoFeatured, setVideoFeatured] = useState(null);

  const history = useHistory();
  const { device } = useSelector((state) => state.theme);

  const [width, setWidth] = useState(window.innerWidth);
  const resizeWindow = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    async function loadVideos() {
      try {
        const { data } = await axios.get('customerSessions/me');
        const featured = data.enrollment[0].product.courses[0].videos[0];
        const playlist_data = [];
        data.enrollment.forEach((e) => {
          e.product.courses.forEach((c) => playlist_data.push(c));
        });
        setPlaylists(playlist_data);
        setVideoFeatured(featured);
      } catch (error) {
        // window.location.href = '/browse';
      }
    }
    loadVideos();
  }, []);

  useEffect(() => {
    resizeWindow();
    window.addEventListener('resize', resizeWindow);
    return () => window.removeEventListener('resize', resizeWindow);
  }, []);

  const onHandleAboutVideo = (v) => {
    setOpen(true);
    setVideo(v);
  };
  const onHandlePlayerVideo = (v) => {
    setOpen(false);
    // setVideo(v);
    if (device !== 'mobile') {
      history.push(`/video/${v.entry_id}`);
    } else {
      window.location.href = `/video/${v.entry_id}`;
    }
    // setVideoPlay(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Header current="browser" />
      <Container
        style={videoPlay ? { overflow: 'hidden', height: '100vh' } : {}}
      >
        {videoPlay && (
          <div id="kalturaWrapper">
            <button
              type="button"
              onClick={() => setVideoPlay(false)}
              className="close"
            >
              <Close />
            </button>
            <iframe
              src={`https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/46258051?iframeembed=true&entry_id=${video.entry_id}`}
              style={{
                overflow: 'hidden',
                width: '100%',
                height: '100vh',
                zIndex: 2,
                position: 'relative',
              }}
              title="Kaltuflix"
              // allowFullScreen
              frameBorder="0"
            />
          </div>
        )}
        {videoFeatured && (
          <div className="video__featured">
            <div
              className="video__featured_content"
              style={{ backgroundImage: `url('${videoFeatured.thumbnail}')` }}
            >
              <h2>{videoFeatured.title}</h2>
              <div>
                <button
                  type="button"
                  onClick={() => onHandlePlayerVideo(videoFeatured)}
                >
                  Assistir
                </button>
                <button
                  type="button"
                  onClick={() => onHandleAboutVideo(videoFeatured)}
                >
                  Mais informações
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="videos__playlists">
          {playlists.length > 0 &&
            playlists.map((e, i) => {
              return (
                <div className="videos__playlist" key={i}>
                  <h4>{e.title}</h4>
                  <div className="videos__playlist_content">
                    <Swiper
                      navigation
                      grabCursor={false}
                      draggable={false}
                      loopAdditionalSlides={
                        width >= 1378
                          ? 4
                          : width >= 998
                          ? 3
                          : width >= 625
                          ? 2
                          : 2
                      }
                      breakpoints={{
                        1378: {
                          slidesPerView: 5,
                          slidesPerGroup: 5,
                        },
                        998: {
                          slidesPerView: 4,
                          slidesPerGroup: 4,
                        },
                        625: {
                          slidesPerView: 3,
                          slidesPerGroup: 3,
                        },
                        0: {
                          slidesPerView: 1,
                          slidesPerGroup: 1,
                        },
                      }}
                      spaceBetween={5}
                      preventClicksPropagation
                      preventClicks
                      scrollbar={{ draggable: false, hide: true }}
                      slideToClickedSlide={false}
                    >
                      {e.videos.map((elem, index) => {
                        return (
                          <SwiperSlide key={index}>
                            <button
                              type="button"
                              className="button_video"
                              style={{
                                backgroundImage: `url(${elem.thumbnail})`,
                              }}
                              onClick={() => onHandleAboutVideo(elem)}
                            >
                              <p>{elem.title}</p>
                            </button>
                          </SwiperSlide>
                        );
                      })}
                    </Swiper>
                  </div>
                </div>
              );
            })}
        </div>
        {video && (
          <ModalCourse
            open={open}
            handleClose={handleClose}
            video={video}
            onHandlePlayerVideo={(v) => onHandlePlayerVideo(v)}
          />
        )}
      </Container>
    </>
  );
}

export default Browse;
