import styled from 'styled-components';
import { darken } from 'polished';

import { Modal } from '@material-ui/core';

export const Container = styled(Modal)`
  #close_mobile {
    position: fixed;
    z-index: 110;
    color: #fff;
    background: none;
    border: none;
    right: 28px;
    top: 42px;
    display: none;
    svg {
      font-size: 40px;
    }
    @media screen and (max-width: 500px) {
      display: block;
    }
  }
  .modal_paper {
    width: 100%;
    max-width: 1100px;
    height: auto;
    max-height: 90vh;
    overflow: auto;
    @media screen and (max-width: 730px) {
      & {
        max-width: 90%;
      }
    }
  }
  .modal_paper .modal_header {
    min-height: 400px;
    border-radius: 10px 10px 0px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    padding: 35px;
    @media screen and (max-width: 500px) {
      min-height: 150px;
    }
  }
  .modal_paper .modal_body h3 {
    z-index: 1000;
    font-weight: 400;
    color: #eee;
  }
  .modal_paper .modal_body p {
    z-index: 1000;
    font-weight: 400;
    color: #c9c9c9;
  }
  .modal_paper .modal_header::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(transparent 0%, rgb(22, 22, 22) 100%);
  }
  .modal_body {
    background: rgb(22, 22, 22);
    padding: 10px 35px;
    border-radius: 0px 0px 10px 10px;
    @media screen and (max-width: 500px) {
      & {
        padding: 25px;
      }
    }
  }
  .modal_body button {
    background-color: ${(props) => props.theme.primary};
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 700;
  }
  .modal_body h4 {
    color: #fff;
    font-weight: 700;
    padding-left: 20px;
    padding-top: 35px;
    font-size: 34px;
    @media screen and (max-width: 500px) {
      & {
        text-align: center;
      }
    }
  }
  .list__videos {
    width: 100%;
    background: rgb(22, 22, 22);
    padding: 25px;
    border-radius: 0px 0px 10px 10px;
    display: grid;
    grid-template-columns: 1fr;
  }
  .list__videos .item {
    background: none;
    width: 100%;
    border: none;
    display: grid;
    grid-template-columns: 1fr 2fr 10fr;
    min-height: 100px;
    padding: 25px 20px;
    @media screen and (max-width: 500px) {
      & {
        grid-template-columns: 1fr;
      }
    }
  }
  .list__videos .item + .item {
    border-top: 2px solid #444;
  }
  .list__videos .item span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .list__videos .item span.number_indicator {
    font-weight: 400;
    font-size: 32px;
    text-align: left;
    color: ${(props) => props.theme.primary};
  }
  .list__videos .item span.thumb_video {
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 10px;
    transition: 300ms;
    @media screen and (max-width: 500px) {
      & {
        min-height: 120px;
        margin: 6px 0px;
      }
    }
  }
  .list__videos .item span.thumb_video div {
    transition: 300ms;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
  .list__videos .item span.thumb_video div svg {
    font-size: 42px;
    color: ${(props) => props.theme.primary};
  }
  .list__videos .item:hover span.thumb_video div {
    opacity: 1;
  }
  .list__videos .item div.video_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 30px;
    @media screen and (max-width: 500px) {
      & {
        margin-top: 16px;
        padding-left: 0px;
        text-align: center;
      }
    }
  }
  .list__videos .item div.video_description div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 500px) {
      & {
        flex-wrap: wrap-reverse;
        justify-content: center;
      }
    }
  }
  .list__videos .item div.video_description div small {
    font-size: 16px;
    color: ${(props) => darken(0.08, props.theme.primary)};
    @media screen and (max-width: 500px) {
      & {
        display: block;
        width: 100%;
      }
    }
  }
  .list__videos .item div.video_description div strong {
    font-size: 22px;
    @media screen and (max-width: 500px) {
      & {
        display: block;
        font-size: 18px;
      }
    }
  }
  .list__videos .item div.video_description p {
    color: #c9c9c9;
    @media screen and (max-width: 500px) {
      & {
        display: block;
        font-size: 14px;
        text-align: center;
        width: 100%;
      }
    }
  }
`;
