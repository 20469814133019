export function signInRequest(email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { email, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

export function updateEmail(email) {
  return {
    type: '@auth/UPDATE_EMAIL',
    payload: { email },
  };
}
