import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';
import { toast } from 'react-toastify';

import { signInSuccess, signOut } from './actions';

export function* signIn({ payload }) {
  try {
    const { token } = payload;
    axios.defaults.headers.Authorization = `Bearer ${token}`;

    const { data } = yield call(
      axios.get,
      `${process.env.REACT_APP_API_URL}/customerSessions/me`
    );

    yield put(signInSuccess(token, data.customer));
  } catch (err) {
    toast.error('Falha na autenticação, verifique seus dados');
  }
}

export function* setToken({ payload }) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    axios.defaults.headers.Authorization = `Bearer ${token}`;
    try {
      yield call(axios.get, '/customerSessions/me');
    } catch (err) {
      if (
        (err.response && err.response.status === 401) ||
        (err.response && err.response.status === 403)
      ) {
        yield put(signOut());
      }
    }
  }
}

export function* setTokenSignIn() {}

export function signOutSaga() {}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_SUCCESS', setTokenSignIn),
  takeLatest('@auth/SIGN_OUT', signOutSaga),
]);
