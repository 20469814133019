import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  margin-top: 120px;
  min-height: calc(100vh - 100px);
  padding-top: 60px;
  h3 {
    color: #444;
    margin-bottom: 30px;
  }
  small {
    color: #999;
    text-transform: uppercase;
  }
  div.about_plan {
    padding: 20px;
    background: #fff;
    border: 1px solid #c9c9c9;
    .small_gray {
      color: #999;
      display: block;
      font-size: 14px;
    }
    .plan_detail {
      color: #444;
      font-size: 18px;
      font-weight: 700;
      display: block;
    }
  }
  i {
    color: #888 !important;
    font-size: 12px;
  }
  table {
    * {
      color: #888;
    }
    thead {
      tr {
        th {
          border-top: none;
        }
      }
    }
    tbody {
      tr {
        td {
          div.method_card {
            width: 60px;
            height: 30px;
            background: #fff;
            margin-right: 8px;
          }
        }
      }
    }
  }
`;
