import styled from 'styled-components';

export const Container = styled.div`
  background: #f9f9f9;
  margin-top: 120px;
  min-height: calc(100vh - 100px);
  height: auto;
  padding: 60px 10px;
  div.container + div.container {
    margin-top: 60px;
  }
  div.container {
    div.header_box {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #c9c9c9;
      padding-bottom: 16px;
      * {
        color: ${(props) => props.theme.primary} !important;
      }
      svg {
        margin-right: 6px;
        font-size: 30px;
      }
      h4 {
        color: #444 !important;
        margin: 0px;
      }
    }
    div.body_box {
      margin-top: 16px;
      .title_gray {
        color: #888;
        display: block;
        text-transform: uppercase;
        font-size: 20px;
      }
      .text_gray {
        color: #888;
        display: block;
      }
      div.row {
        div.col-md-9 {
          form + form {
            margin-top: 16px;
          }
          form {
            padding: 16px;
            border: 1px solid #eee;
            label {
              color: #999 !important;
              font-size: 14px;
              margin: 0px;
            }
            button {
              background: #555;
              padding: 5px 10px;
              border-radius: 4px;
              font-size: 12px;
              color: #fff;
              text-decoration: none !important;
            }
            button.button_red {
              background: #e50914;
            }
            button.button_red:disabled {
              opacity: 0.6;
            }
          }
          div.thumb_list {
            display: flex;
            justify-content: flex-start;
            overflow: hidden;
            div.item {
              width: 120px;
              height: 80px;
              border-radius: 6px;
            }
            div.item + div.item {
              margin-left: -30px;
            }
            span {
              display: block;
              color: #444 !important;
              margin: auto 20px;
            }
          }
          h4 {
            color: #444;
            font-size: 24px;
            margin-bottom: 6px;
          }
          h5 {
            color: #666;
          }
          p {
            color: #888;
            width: 100%;
            max-width: 500px;
            font-size: 12px;
          }
          strong {
            display: block;
            color: #333;
            margin: 3px 0px;
          }
          button {
            background: none;
            border: none;
            color: #007bff;
            display: block;
            text-align: right;
            margin-left: auto;
            margin-bottom: 8px;
            &:hover {
              text-decoration: underline;
            }
          }
          button.red_button {
            color: #e50914;
          }
        }
      }
    }
  }
`;
