import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 100%;
  top: 0px;
  padding: 30px 3.5rem 0;
  z-index: 10;
  .black_bg {
    background: #000;
  }
  img {
    height: 100%;
    max-height: 65px;
    @media screen and (max-width: 500px) {
      & {
        margin: 0px auto !important;
        width: 90%;
      }
    }
  }
  div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 500px) {
      & {
        justify-content: center;
      }
      div {
        justify-content: center;
        align-items: center;
        display: flex;
        margin-top: 10px;
        width: 100%;
      }
    }
    a {
      color: #fff;
      display: block;
      margin-right: 16px;
      padding-right: 16px;
      border-right: 1px solid #999;
      text-decoration: none;
    }
    button {
      border: 0;
      background-color: ${(props) => props.theme.primary};
      line-height: normal;
      padding: 10px 20px;
      font-weight: 400;
      font-size: 1rem;
      color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 6px;
      }
    }
  }
`;
