import React, { useState, useEffect } from 'react';

import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Container } from './styles';

import { signInSuccess } from '../../../store/modules/auth/actions';
import { setColors } from '../../../store/modules/theme/actions';

function Auth({ match }) {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadToken() {
      const { token } = match.params;
      try {
        axios.defaults.headers.Authorization = `Bearer ${token}`;
        const { data } = await axios.get('/customerSessions/me');
        const { data: dataCompany } = await axios.get(
          `/companies/${data.company_id}`
        );
        dispatch(
          setColors({
            primary: dataCompany.colorPrimary,
            secondary: dataCompany.colorSecundary,
            background: dataCompany.background,
            id: btoa(dataCompany.company_id),
            logo: `https://admin.flix.tupi.io/assets/${dataCompany.logo}`,
            link: dataCompany.link,
            device: 'mobile',
          })
        );
        document.body.style.background = '#222';
        dispatch(signInSuccess(token, data));
      } catch (err) {
        if (
          (err.response && err.response.status === 403) ||
          (err.response && err.response.status === 401)
        ) {
          setMsg('Token inválido');
        } else {
          setMsg('Ocorreu um erro, tente novamente mais tarde.');
        }
      }
      setLoading(false);
    }
    loadToken();
  }, []); // eslint-disable-line

  return (
    <Container>
      <div id="auth_container">
        {loading && (
          <>
            <h2>Autenticando</h2>
            <CircularProgress style={{ fontSize: '60px', color: '#3757a1' }} />
          </>
        )}
        {msg !== '' && <h4 className="text-center">{msg}</h4>}
      </div>
    </Container>
  );
}

export default Auth;
