import React from 'react';

import { Switch, Route, Redirect } from 'react-router-dom';
import Browse from './Browse';
import Profile from './Profile';
import BillingActivity from './BillingActivity';
import Video from './Video';

export default function MainPage() {
  return (
    <Switch>
      <Route exact path="/browse" component={Browse} />
      <Route exact path="/profile" component={Profile} />
      <Route exact path="/billing-activity" component={BillingActivity} />
      <Route exact path="/video/:entry_id" component={Video} />
      <Redirect to="/browse" />
    </Switch>
  );
}
