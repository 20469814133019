import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px;
  background: #000;
  height: 100vh;
  overflow: hidden;
  #kalturaWrapper {
    background: #000;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    .close {
      padding: 0;
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 3;
      color: #fff;
      opacity: 1;
      transition: 300ms;
      & svg {
        font-size: 40px;
      }
    }
  }
`;
