import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PersonOutline, HomeOutlined, ExitToApp } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { signOut } from '../../../../store/modules/auth/actions';
import { Container } from './styles';

function Header({ className, current }) {
  const dispatch = useDispatch();
  const { logo, device } = useSelector((state) => state.theme);

  return (
    <Container className={className && className}>
      <div className="d-flex align-items-center justify-content-between">
        <img src={logo} alt="Logomarca" className="mr-3" />
        <div>
          {current !== 'browser' && (
            <Link to="/browser" className="text-dark">
              <HomeOutlined /> Início
            </Link>
          )}
          {current !== 'profile' && device !== 'mobile' && (
            <Link to="/profile">
              <PersonOutline /> Perfil
            </Link>
          )}
          {device !== 'mobile' && (
            <button
              type="button"
              onClick={() => {
                dispatch(signOut());
              }}
            >
              <ExitToApp /> Sair
            </button>
          )}
        </div>
      </div>
    </Container>
  );
}

export default Header;
