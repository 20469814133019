import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, Button, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';

import { signInSuccess } from '../../../store/modules/auth/actions';
import { setColors } from '../../../store/modules/theme/actions';

import { Container } from './styles';
import Header from '../components/Header';

function Home({ match }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    async function loadCompany() {
      try {
        const { data } = await axios.get(
          `/companies/${atob(match.params.companyId)}`
        );
        dispatch(
          setColors({
            primary: data.colorPrimary,
            secondary: data.colorSecundary,
            background: data.background,
            id: match.params.companyId,
            logo: `https://admin.flix.tupi.io/assets/${data.logo}`,
            link: data.link,
          })
        );
        setHasCompany(true);
      } catch (err) {
        window.location.href = '/error';
      }
    }
    if (match.params.companyId) {
      loadCompany();
    }
    // eslint-disable-next-line
  }, [match.params.companyId]); 

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  const [msg, setMsg] = useState({ type: '', value: '' });

  async function handleSubmit(e) {
    e.preventDefault();
    setMsg({ type: '', value: '' });
    const lock_error = {
      email: '',
      password: '',
    };

    if (email.indexOf('@') === -1) {
      lock_error.email = 'Email inválido';
    } else if (email.split('@')[1].indexOf('.') === -1) {
      lock_error.email = 'Email inválido';
    }
    if (lock_error.email !== '' || lock_error.password !== '') {
      setErrors(lock_error);
      return;
    }
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/customerSessions`,
        {
          email,
          password,
        }
      );
      axios.defaults.headers.Authorization = `Bearer ${data.token}`;
      dispatch(signInSuccess(data.token, data.customer));
    } catch (err) {
      if (err.response) {
        setMsg({
          type: 'error',
          value: err.response.data.message,
        });
      }
    }
    setLoading(false);
  }
  return (
    <>
      {hasCompany ? (
        <>
          <Header path="login" />
          <Container>
            <div id="auth_container">
              <div className="d-flex  flex-column">
                <h2>Entrar</h2>
                <form onSubmit={handleSubmit} className="d-flex flex-column">
                  {msg.value !== '' && (
                    <Alert severity={msg.type} className="mb-3">
                      {msg.value}
                    </Alert>
                  )}
                  <TextField
                    label="Email"
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setErrors({
                        email: '',
                        password: '',
                      });
                      setEmail(e.target.value);
                    }}
                    variant="filled"
                    className="mb-2 bg-white"
                    error={errors.email !== ''}
                  />
                  <TextField
                    label="Senha"
                    type="password"
                    value={password}
                    onChange={(e) => {
                      setErrors({
                        email: '',
                        password: '',
                      });
                      setPassword(e.target.value);
                    }}
                    variant="filled"
                    className="mb-2 bg-white"
                    error={errors.password !== ''}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="mt-2 mb-4"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{ color: '#fff', fontSize: '16px' }}
                      />
                    ) : (
                      'Entrar'
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </Container>
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center bg-white"
          style={{ minHeight: '100vh' }}
        >
          <h5 className="text-dark">Carregando...</h5>
        </div>
      )}
    </>
  );
}

export default Home;
