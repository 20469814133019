import React from 'react';

import { Table } from 'react-bootstrap';

import Header from '../components/Header';

import { Container } from './styles';

function BillingActivity() {
  return (
    <>
      <Header current="profile" className="black_bg" />
      <Container>
        <div className="container">
          <h3>Detalhes de cobrança</h3>
          <small>Sua Assinatura</small>
          <div className="about_plan">
            <span className="small_gray">Seu plano</span>
            <span className="plan_detail">
              NOME DO PRODUTO por R$ XX,XX/mês
            </span>
            <br />
            <span className="small_gray">Sua próxima fatura</span>
            <span className="plan_detail">xx de xxxxxxxxxxxxxxx de xxxx</span>
          </div>
          <i>
            A mensalidade é cobrada no início de cada período de cobrança.
            Alguns dias podem transcorrer antes da cobrança ser exibida na sua
            conta.
          </i>
          <Table responsive="lg" hover className="mt-4">
            <thead>
              <tr>
                <th>Data</th>
                <th>Descrição</th>
                <th>Período</th>
                <th>Forma de Pagamento</th>
                <th className="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
              <tr>
                <td>xx/xx/xxxx</td>
                <td>Lorem ipsum dolor sit amet</td>
                <td>xx/xx/xxxx - xx/xx/xxxx</td>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="method_card" />
                    **** **** **** xxxx
                  </div>
                </td>
                <td className="text-right">R$ XX,XX</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
    </>
  );
}

export default BillingActivity;
