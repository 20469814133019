import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  transition: 500ms;
  .loading {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
  .loadingForm__content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgb(255 255 255 / 70%);
    z-index: 999;
    .loadingForm {
      position: absolute;
      z-index: 9999;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      color: #333;
    }
  }
  div#wrap {
    width: 100%;
    max-width: 625px;
    background: #fff;
    margin: 0px auto 50px;
    * {
      color: #333 !important;
    }
    padding: 5px;
    display: flex;
    flex-direction: column;
    #grid_order {
      margin: 25% 0px 0;
      text-align: center;
      h1 {
        font-weight: 500;
      }
      p {
        margin: 15px 0 10px;
      }
      a {
        background: ${(props) => props.theme.primary};
        color: #fff !important;
        margin-top: 10px;
      }
    }
  }
`;
