import React from 'react';

import { Close } from '@material-ui/icons';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container } from './styles';

function Video({ match }) {
  const history = useHistory();
  const { device } = useSelector((state) => state.theme);

  return (
    <Container>
      <div id="kalturaWrapper">
        <button
          type="button"
          onClick={() => {
            if (device !== 'mobile') {
              history.push(`/browse`);
            } else {
              window.location.href = `/browse`;
            }
          }}
          className="close"
        >
          <Close />
        </button>
        <iframe
          src={`https://cdnapisec.kaltura.com/p/2601552/embedPlaykitJs/uiconf_id/46258051?iframeembed=true&entry_id=${match.params.entry_id}`}
          style={{
            overflow: 'hidden',
            width: '100%',
            height: '100vh',
            zIndex: 2,
            position: 'relative',
          }}
          title="Kaltuflix"
          // allowFullScreen
          frameBorder="0"
        />
      </div>
    </Container>
  );
}

export default Video;
