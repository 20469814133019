import React, { useEffect, useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import {
  AssignmentIndOutlined,
  SubscriptionsOutlined,
} from '@material-ui/icons';
import { Form } from 'react-bootstrap';

// import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { updateEmail } from '../../../store/modules/auth/actions';

import ModalConfirmCancel from './components/ModalConfirmCancel';
import Header from '../components/Header';

import { Container } from './styles';

function Profile() {
  const [products, setProducts] = useState([]);
  const [editName, setEditName] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  // const history = useHistory();
  const dispatch = useDispatch();

  const { email, name } = useSelector((state) => state.auth.user);

  const [password, setPassword] = useState({
    old: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [values, setValues] = useState({
    name,
    email,
  });
  const [valuesBackup, setValuesBackup] = useState({
    name,
    email,
  });

  useEffect(() => {
    async function loadProduct() {
      const { data } = await axios.get('customerSessions/me');
      let productsArr = [];
      for (let i = 0; i < data.enrollment.length; i++) {
        const product = data.enrollment[i].product;
        productsArr.push({
          id: product.id,
          title: product.title,
          description: product.description,
        });
      }
      setProducts(productsArr);
    }
    loadProduct();
  }, []);

  async function handleUpdateEmail(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customer/update/email`,
        {
          email: values.email,
        }
      );
      dispatch(updateEmail(values.email));
      setValuesBackup({
        ...valuesBackup,
        email: values.email,
      });
      setEditEmail(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
    }
    setLoading(false);
  }

  async function handleUpdatePassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/customer/update/password`,
        {
          oldPassword: password.old,
          password: password.newPassword,
        }
      );
      setEditPassword(false);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      }
    }
    setLoading(false);
  }

  return (
    <>
      <Header className="black_bg" current="profile" />
      <ModalConfirmCancel
        show={show}
        handleClose={() => setShow(false)}
        handleSubmit={() => {}}
      />
      <Container>
        <div>
          <div className="container">
            <div className="header_box">
              <AssignmentIndOutlined />
              <h4>Conta</h4>
            </div>
            <div className="body_box">
              <div className="row">
                <div className="col-md-3">
                  <span className="title_gray">Dados da Conta</span>
                </div>
                <div className="col-md-9">
                  <div className="d-flex justify-content-between align-items-start">
                    <div>
                      {!editName && <h4>{valuesBackup.name}</h4>}
                      {!editEmail && <strong>{valuesBackup.email}</strong>}
                      {!editPassword && (
                        <span className="text_gray">Senha: ********</span>
                      )}
                      {editName && (
                        <Form>
                          <Form.Group className="mb-0">
                            <Form.Label>Editar Nome</Form.Label>
                            <Form.Control
                              size="sm"
                              value={values.name}
                              onChange={(e) =>
                                setValues({ ...values, name: e.target.value })
                              }
                            />
                            <div className="d-flex justify-content-start mt-2">
                              <button
                                type="button"
                                className="button_red"
                                disabled={values.name.length === 0}
                              >
                                Salvar
                              </button>
                              <button
                                type="button"
                                className="ml-2"
                                onClick={() => setEditName(false)}
                              >
                                Cancelar
                              </button>
                            </div>
                          </Form.Group>
                        </Form>
                      )}
                      {editEmail && (
                        <Form onSubmit={handleUpdateEmail}>
                          <Form.Group className="mb-0">
                            <Form.Label>Editar E-mail</Form.Label>
                            <Form.Control
                              size="sm"
                              type="email"
                              value={values.email}
                              onChange={(e) =>
                                setValues({ ...values, email: e.target.value })
                              }
                              disabled={loading}
                            />
                            <div className="d-flex justify-content-start mt-2">
                              <button
                                type="submit"
                                className="button_red"
                                disabled={
                                  values.email.length < 3 ||
                                  values.email.indexOf('@') === -1 ||
                                  values.email.indexOf('.') === -1 ||
                                  loading
                                }
                              >
                                {loading && (
                                  <CircularProgress
                                    color="primary"
                                    size="16px"
                                  />
                                )}
                                Salvar
                              </button>
                              <button
                                type="button"
                                className="ml-2"
                                disabled={loading}
                                onClick={() => setEditEmail(false)}
                              >
                                Cancelar
                              </button>
                            </div>
                          </Form.Group>
                        </Form>
                      )}
                      {editPassword && (
                        <Form onSubmit={handleUpdatePassword}>
                          <Form.Label>Editar Senha</Form.Label>
                          <Form.Control
                            size="sm"
                            type="password"
                            placeholder="Senha Antiga"
                            value={password.old}
                            onChange={(e) =>
                              setPassword({ ...password, old: e.target.value })
                            }
                            disabled={loading}
                          />
                          <Form.Control
                            size="sm"
                            type="password"
                            placeholder="Nova senha"
                            className="mt-2"
                            value={password.newPassword}
                            onChange={(e) =>
                              setPassword({
                                ...password,
                                newPassword: e.target.value,
                              })
                            }
                            disabled={loading}
                          />
                          <Form.Control
                            size="sm"
                            type="password"
                            className="mt-2"
                            placeholder="Confirmar nova senha"
                            value={password.confirmNewPassword}
                            onChange={(e) =>
                              setPassword({
                                ...password,
                                confirmNewPassword: e.target.value,
                              })
                            }
                            disabled={loading}
                          />
                          <div className="d-flex justify-content-start mt-2">
                            <button
                              type="submit"
                              className="button_red"
                              disabled={
                                password.old.length < 6 ||
                                password.newPassword.length < 6 ||
                                password.confirmNewPassword.length < 6 ||
                                loading
                              }
                            >
                              {loading && (
                                <CircularProgress color="primary" size="16px" />
                              )}
                              Salvar
                            </button>
                            <button
                              type="button"
                              className="ml-2"
                              disabled={loading}
                              onClick={() => {
                                setEditPassword(false);
                                setPassword({
                                  old: '',
                                  newPassword: '',
                                  confirmNewPassword: '',
                                });
                              }}
                            >
                              Cancelar
                            </button>
                          </div>
                        </Form>
                      )}
                    </div>
                    <div>
                      {/* <button
                        type="button"
                        onClick={() => {
                          setEditName(true);
                          setValues({
                            ...values,
                            name: valuesBackup.name,
                          });
                        }}
                      >
                        Alterar nome
                      </button> */}
                      <button
                        type="button"
                        onClick={() => {
                          setEditEmail(true);
                          setValues({
                            ...values,
                            email: valuesBackup.email,
                          });
                        }}
                      >
                        Alterar email da conta
                      </button>
                      <button
                        type="button"
                        onClick={() => setEditPassword(true)}
                      >
                        Alterar senha
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="container">
            <div className="header_box">
              <SubscriptionsOutlined />
              <h4>Cursos</h4>
            </div>
            <div className="body_box">
              <div className="row">
                <div className="col-md-3">
                  <span className="title_gray">Meus cursos</span>
                </div>
                <div className="col-md-9">
                  {products.length > 0 &&
                    products.map((e, i) => {
                      return (
                        <div key={i}>
                          <div className="d-flex justify-content-between align-items-start">
                            <h5>{e.title}</h5>
                          </div>
                          <div>
                            {/* <button
                              type="button"
                              onClick={() => history.push('/billing-activity')}
                            >
                              Detalhamento
                            </button> */}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </Container>
    </>
  );
}

export default Profile;
