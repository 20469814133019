import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';

import HeaderPrimary from '../components/HeaderPrimary';


import { Container } from './styles';

function OrderDetail({ match }) {
  
  const [product, setProduct] = useState(null);

  useEffect(() => {
    async function loadProduct() {
      
      const { data } = await axios.get(`/enrollments/products/${match.params.productId}`);
      setProduct(data);
      
    }
    loadProduct();
  }, [match.params.productId]);

  return (
    <Container>
      {product ? (
        <>
          <HeaderPrimary current="browser" />
          <div id="wrap">
            <div>
              <div id="grid_order">
                <h1>PARABÉNS PELA COMPRA</h1>
                <p>Clique no botão abaixo e confira tudo sobre <br/><strong>{product.title}</strong>.</p> 
                <a href="https://app.elleconsulting.com.br" className="btn">Área do aluno</a>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="loading">
          <CircularProgress size="100px" className="mt-3" />
        </div>
      )}
    </Container>
  );
}

export default OrderDetail;
