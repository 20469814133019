import React from 'react';

import { useSelector } from 'react-redux';
import { Container } from './styles';

function HeaderPrimary() {
  const { logo, link } = useSelector((state) => state.theme);

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-between">
        <a href={link}>
          <img src={logo} alt="Logomarca" className="mr-3" />
        </a>
      </div>
    </Container>
  );
}

export default HeaderPrimary;
