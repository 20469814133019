import styled, { keyframes } from 'styled-components';

import { darken } from 'polished';

const Fade = keyframes`
  0%{
    opacity: 0;
    transform: scale(1.5);
  }100%{
    opacity: 1;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  overflow: hidden;
  animation: ${Fade} 400ms cubic-bezier(0.075, 0.82, 0.165, 1);
  background: linear-gradient(
    155deg,
    #333 0%,
    #333 50%,
    ${(props) => props.theme.primary} 50%,
    ${(props) => props.theme.primary} 65%,
    ${(props) => darken(0.1, props.theme.primary)} 65%,
    ${(props) => props.theme.primary} 100%
  );
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center center;
  @media only screen and (max-width: 990px) {
    background-size: auto 100%;
  }
  min-height: 100vh;
  border-bottom: 8px solid #222;
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.primary};
  }
  .MuiFormControl-root {
    border-radius: 4px;
  }
  div#auth_container {
    width: 100%;
    max-width: 450px;
    @media only screen and (max-width: 990px) {
      width: 90%;
      padding: 40px 25px;
    }
    padding: 40px 55px;
    background: rgba(0, 0, 0, 0.5);
    h2 {
      color: #fff;
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 28px;
    }
    form {
      .auth_container__register {
        font-size: 16px;
        color: #737373;
        a {
          color: #fff;
          font-size: 16px;
          margin: 0px 0px 0px 10px;
        }
      }
    }
    button {
      background: ${(props) => props.theme.primary} !important;
      padding: 15px 0;
      font-weight: bold;
    }
  }
`;
