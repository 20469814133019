import styled from 'styled-components';

export const Container = styled.div`
  margin: 0px;
  background: #000;
  min-height: 100vh;
  overflow: hidden;
  #kalturaWrapper {
    background: #000;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 999;
    .close {
      padding: 0;
      background-color: transparent;
      border: 0;
      position: absolute;
      top: 20px;
      right: 30px;
      z-index: 3;
      color: #fff;
      opacity: 1;
      transition: 300ms;
      & svg {
        font-size: 40px;
      }
    }
  }
  .video__featured {
    display: grid;
    grid-column: 1 / 13;
    height: 80vh !important;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      bottom: 0px;
      left: 0px;
      background: linear-gradient(transparent 15%, #000 100%);
      width: 100%;
      height: 100px;
    }
    @media only screen and (max-width: 990px) {
      height: auto;
      background-size: auto 100%;
      background-position: center;
    }
    .video__featured_content {
      background-size: 100% auto;
      background-repeat: no-repeat;
      padding-top: calc(70vh - 200px);
      padding-left: 4.5rem;
      color: #fff;
      @media only screen and (max-width: 990px) {
        height: auto;
        padding-bottom: 50px;
        background-size: auto 100%;
        background-position: center;
        padding: 160px 30px 30px 30px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 15%);
        z-index: 1;
      }
      h2 {
        font-size: 3rem;
        padding-bottom: 1rem;
        max-width: 40%;
        z-index: 2;
        position: relative;
        @media only screen and (max-width: 990px) {
          font-size: 50px;
          line-height: 1;
          max-width: 100%;
        }
        @media screen and (max-width: 500px) {
          font-size: 34px;
        }
      }
      @media screen and (max-width: 500px) {
        h2 {
          text-align: center;
          margin-top: auto;
        }
        > div {
          margin-bottom: 20px;
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
      }
      > div {
        @media only screen and (max-width: 990px) {
          flex-direction: column;
        }
        display: flex;
        z-index: 2;
        position: relative;
        button {
          @media only screen and (max-width: 990px) {
            margin-bottom: 10px;
          }
          cursor: pointer;
          font-size: 1.4rem;
          color: #fff;
          outline: none;
          border: none;
          font-weight: 700;
          border-radius: 0.2vw;
          padding-left: 2rem;
          padding-right: 2rem;
          margin-right: 1rem;
          padding-top: 0.5rem;
          background-color: rgba(51, 51, 51, 0.5);
          padding-bottom: 0.5rem;
          &:hover {
            color: #000;
            background-color: #e6e6e6;
            transition: all 0.2s;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
          }
          @media screen and (max-width: 500px) {
            margin-right: 0px;
            font-size: 16px;
          }
        }
      }
    }
  }
  .videos__playlist {
    margin: 3vw 0;
    position: relative;
    top: -40px;
    h4 {
      color: #fff;
      font-size: 16px;
      @media only screen and (max-width: 990px) {
        margin: 0 20px 0.7rem;
      }
      margin: 0 4rem 0.7rem;
    }
    .videos__playlist_content {
      @media only screen and (max-width: 990px) {
        padding: 0 20px 0.7rem;
      }
      padding: 0 60px;
      .swiper-container {
        overflow: visible;
        .swiper-slide {
          transition: transform 0.3s;
          display: block;
          &:hover {
            transition: transform 0.3s;
            -ms-transform: scale(1.35);
            -webkit-transform: scale(1.35);
            transform: scale(1.35);
            z-index: 1;
          }
          button {
            border: 0;
            display: block;
            margin: auto;
            width: 100%;
            max-width: 280px;
            background-size: cover;
            background-color: #000;
            background-repeat: no-repeat;
            height: 160px;
            position: relative;
          }
          p {
            color: white;
            position: absolute;
            bottom: 0;
            background: rgb(0 0 0 / 54%);
            width: 100%;
            padding: 10px 20px;
            margin: 0;
            text-align: left;
            text-transform: capitalize;
            
          }
          img {
            border-radius: 2px;
            width: auto;
            height: 190px;
          }
        }
      }
    }
  }
`;
