export const normalizeDate = (value) => {
  if (!value) {
    return value;
  }

  const formated = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');

  return formated;
};

export const normalizeNumber = (value) => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/\D/g, '');

  return onlyNums;
};

export const normalizePhone = (value) => {
  if (!value) {
    return value;
  }

  let onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 0) {
    onlyNums = ``;
  } else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  } else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  } else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  } else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      6
    )}-${onlyNums.slice(6, 11)}`;
  } else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
      2,
      7
    )}-${onlyNums.slice(7, 11)}`;
  }

  return onlyNums;
};
