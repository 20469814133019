import React from 'react';

import { Backdrop, Fade } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Container } from './styles';

function ModalCourse({ handleClose, video, open, onHandlePlayerVideo }) {
  return (
    <Container
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modal_paper">
          <button type="button" id="close_mobile" onClick={handleClose}>
            <Close />
          </button>
          <div
            className="modal_header"
            style={{
              backgroundImage: `url('${video.thumbnail}')`,
            }}
          />
          <div className="modal_body">
            <h3>{video.title}</h3>
            <p>{video.description}</p>
            {video.entry_id ? (
              <button type="button" onClick={() => onHandlePlayerVideo(video)}>
                Assistir
              </button>
            ) : (
              <h4>Vídeos</h4>
            )}
            {/* <div className="list__videos">
              {video.videos &&
                video.videos.map((video, index) => (
                  <button
                    key={index}
                    type="button"
                    className="item"
                    onClick={() => onHandlePlayerVideo(video)}
                  >
                    <span className="number_indicator">{index + 1}</span>
                    <span
                      className="thumb_video"
                      style={{ backgroundImage: `url(${video.thumbnail})` }}
                    >
                      <div>
                        <PlayCircleFilled />
                      </div>
                    </span>
                    <div className="video_description">
                      <div>
                        <strong>{video.title}</strong>
                        <small>{video.duration}</small>
                      </div>
                      <p>{video.description}</p>
                    </div>
                  </button>
                ))}
            </div> */}
          </div>
        </div>
      </Fade>
    </Container>
  );
}

export default ModalCourse;
