import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import AuthPage from './pages/auth/AuthPage';
import MainPage from './pages/main/MainPage';
import Checkout from './pages/auth/Checkout';
import OrderDetail from './pages/auth/OrderDetail';

export default function Routes() {
  const { signed } = useSelector((state) => state.auth);
  const theme = useSelector((state) => state.theme);
  return (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route
          exact
          path="/checkout/:companyId/:productId"
          component={Checkout}
        />
        <Route
          exact
          path="/order-detail/:productId"
          component={OrderDetail}
        />
        {!signed ? <AuthPage /> : <MainPage />}
        {!signed ? <Redirect to="/auth" /> : <Redirect to="/" />}
      </Switch>
    </ThemeProvider>
  );
}
