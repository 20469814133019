import styled from 'styled-components';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: #fff;
  transition: 500ms;
  .loading {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
  .loadingForm__content {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    background: rgb(255 255 255 / 70%);
    z-index: 999;
    .loadingForm {
      position: absolute;
      z-index: 9999;
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
    }
    p {
      margin-top: 10px;
      margin-bottom: 0px;
      color: #333;
    }
  }
  div#wrap {
    width: 100%;
    max-width: 625px;
    background: #fff;
    margin: 0px auto 50px;
    * {
      color: #333 !important;
    }
    padding: 5px;
    display: flex;
    flex-direction: column;
    #grid_2 {
      display: flex;
      flex-direction: column;
      margin-bottom: auto;
      padding: 0;
      #action_section {
        padding: 15px 20px;
        h3 {
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 30px;
          margin-top: 30px;
        }
        .plans {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 10px;
          div.item_plan {
            display: flex;
            padding: 15px;
            border: 1px solid #dbdbdb;
            justify-content: space-between;
            align-items: flex-start;
            border-radius: 5px;
            flex-direction: column;
            .description {
              display: flex;
              padding: 7.5px 0 0 0;
              div {
                h5 {
                  margin: 0 0 3px;
                  font-weight: 600;
                }
                p {
                  font-size: 14px;
                  color: #666 !important;
                }
              }
            }
            .price {
              margin-top: 5px;
              h4 {
                font-weight: 600;
                font-size: 24px;
              }
              table {
                tr {
                  td {
                    font-size: 14px;
                    border: 1px solid #e6e6e6;
                    padding: 5px 10px;
                  }
                }
              }
              span {
                font-size: 10px;
                font-style: italic;
              }
            }
          }
          div.item_plan:hover {
            background: #f9f9f9;
          }
        }
      }
      .resume {
        background: #f5f5f5;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-bottom: 20px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        > div {
          h5 {
            margin: 0 0 3px;
            font-size: 16px;
          }
          p {
            margin: 0px;
            font-size: 14px;
          }
          span {
            margin: 0px;
            color: #666 !important;
            font-size: 14px;
          }
        }
        p.price {
          font-size: 23px;
          font-weight: 600;
        }
      }
    }
    #form_account {
      padding: 0px;
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      div.input-group {
        margin-bottom: 20px;
        &.col-100 > div {
          width: 100%;
        }
        &.col-50 {
          display: flex;
          justify-content: space-between;
          > div {
            width: calc(50% - 10px);
          }
        }
        &.col-30 {
          display: flex;
          justify-content: space-between;
          > div {
            width: calc(33.3334% - 10px);
          }
        }
      }
      .accountCustomerLogin {
        display: flex;
        p {
          margin: 0px;
        }
      }
      button {
        background: ${(props) => props.theme.primary};
        width: 100%;
        margin-top: 20px;
        border: none;
        border-radius: 3px;
        padding: 15px 0px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 1.5px;
        font-size: 17px;
        color: #fff !important;
        &.account:not(account) {
          border: 1px solid #fe0102;
          background: #fff;
          color: #fe0102 !important;
        }
        &.logout {
          background: #fff;
          color: #333 !important;
          width: auto;
          padding: 0;
          margin: -1px 0 0;
          text-transform: none;
          letter-spacing: normal;
          font-weight: normal;
          text-decoration: underline;
          margin-left: 10px;
        }
      }
    }
  }
`;
