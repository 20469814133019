import React from 'react';

// import { Container } from './styles';

function Error() {
  return (
    <div>
      <h2>Página não encontrada</h2>
    </div>
  );
}

export default Error;
