import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { CircularProgress, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ReCAPTCHA from 'react-google-recaptcha';

import HeaderPrimary from '../components/HeaderPrimary';
import { signInSuccess, signOut } from '../../../store/modules/auth/actions';
import { setColors } from '../../../store/modules/theme/actions';

import { Container } from './styles';
import { normalizeNumber, normalizeDate } from './components/utils/normalize';

function Checkout({ match }) {
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [account, setAccount] = useState(null);
  const [typePassword, setTypePassword] = useState('password');

  const [company, setCompany] = useState(true);
  const [product, setProduct] = useState(null);
  const [customer, setCustomer] = useState(false);

  const [amount, setAmount] = useState(false);

  const [values, setValues] = useState({
    email: '',
    password: '',
    name: '',
    cpf: '',
    phone: '',
    coupon: '',
    cardName: '',
    cardNumber: '',
    cardDate: '',
    cardCvv: '',
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    name: '',
    cpf: '',
    phone: '',
    cardName: '',
    cardNumber: '',
    cardDate: '',
    cardCvv: '',
  });
  const [errorLogin, setErrorLogin] = useState(null);
  const [errorCoupon, setErrorCoupon] = useState(null);
  const [errorPayment, setErrorPayment] = useState(null);

  const reRef = useRef();

  const dispatch = useDispatch();

  const { id: companyId } = useSelector((state) => state.theme);

  useEffect(() => {
    async function loadCompany() {
      try {
        const { data: dataProduct } = await axios.get(
          `/getProduct/${atob(match.params.productId)}`
        );
        setProduct(dataProduct);
        const { data } = await axios.get(
          `/companies/${dataProduct.company_id}`
        );
        setCompany(data);
        setAmount(dataProduct.value);
        dispatch(
          setColors({
            primary: data.colorPrimary,
            secondary: data.colorSecundary,
            background: data.background,
            id: match.params.companyId,
            logo: `https://admin.flix.tupi.io/assets/${data.logo}`,
            link: data.link,
          })
        );
        setHasCompany(true);

        const { data: dataCustomer } = await axios.get('/customerSessions/me');
        setCustomer(dataCustomer);
        setValues({
          ...values,
          name: dataCustomer.name,
          password: dataCustomer.password,
          email: dataCustomer.email,
          cpf: dataCustomer.cpf,
          phone: dataCustomer.phone,
        });
        const { status } = await axios.post('payments/discount/mail', {
          email: dataCustomer.email,
        });
        if (status === 200 && Number(dataProduct.value) > 3800) {
          setAmount(3800);
        }
      } catch (err) {
        // window.location.href = '/error';
      }
    }
    if (match.params.companyId) {
      loadCompany();
    }
    setLoading(false);
    const scriptGoogle = document.createElement('script');
    scriptGoogle.async = true;
    scriptGoogle.src =
      'https://www.googletagmanager.com/gtag/js?id=UA-158756523-2';
    const scriptExecuter = document.createElement('script');
    scriptExecuter.src = '/script.js';
    document.body.appendChild(scriptGoogle);
    document.body.appendChild(scriptExecuter);
    // eslint-disable-next-line
  }, [match.params.companyId]);

  async function handleSubmit(e) {
    e.preventDefault();
    setErrors({
      email: '',
      password: '',
      name: '',
      cpf: '',
      phone: '',
      cardName: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    });
    setErrorPayment(null);
    const lock_errors = {
      email: '',
      password: '',
      name: '',
      cpf: '',
      phone: '',
      cardName: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    };
    let count = 0;
    let countCustomer = 0;
    if (values.name === '') {
      lock_errors.name = 'Preencha seu nome';
      count += 1;
      countCustomer += 1;
    }
    if (values.email.indexOf('@') === -1) {
      lock_errors.email = 'Preencha seu email';
      count += 1;
      countCustomer += 1;
    } else if (values.email.split('@')[1].indexOf('.') === -1) {
      lock_errors.email = 'Preencha seu email';
      count += 1;
      countCustomer += 1;
    }
    if (values.password === '') {
      lock_errors.password = 'Preencha sua senha';
      count += 1;
      countCustomer += 1;
    }
    if (values.cpf === '') {
      lock_errors.cpf = 'Preencha seu CPF';
      count += 1;
      countCustomer += 1;
    }
    if (values.cardName === '') {
      lock_errors.cardName = 'Preencha o nome impresso no seu cartão';
      count += 1;
    }
    if (values.cardNumber === '') {
      lock_errors.cardNumber = 'Preencha o número do seu cartão';
      count += 1;
    }
    if (values.cardDate === '') {
      lock_errors.cardDate = 'Preencha a data de validade do seu cartão';
      count += 1;
    }
    if (values.cardCvv === '') {
      lock_errors.cardCvv = 'Preencha o código de segurança do seu cartão';
      count += 1;
    }
    if (count > 0 || countCustomer > 0) {
      setErrors(lock_errors);
      if (countCustomer > 0) {
        setErrorPayment('Acesse ou crie sua conta para finalizar o pagamento');
      }
      return false;
    }
    setLoadingForm(true);
    try {

      const token = await reRef.current.executeAsync();
      reRef.current.reset();

      if(!token){
        setErrorPayment('Acesse ou crie sua conta para finalizar o pagamento');
        return false;
      }
      
      const obj = {
        name: values.name,
        email: values.email,
        password: values.password,
        cpf: values.cpf,
        phone: values.phone,
        cardName: values.cardName,
        cardNumber: values.cardNumber,
        cardMonth: `${values.cardDate[0]}${values.cardDate[1]}`,
        cardYear: `${values.cardDate[3]}${values.cardDate[4]}`,
        cardCvv: values.cardCvv,
        coupon: values.coupon,
        product_id: atob(match.params.productId),
        company_id: atob(companyId),
        token,
      };
      const { status, data } = await axios.post('payments', obj);
      setLoadingForm(false);
      if (status === 201) {
        axios.defaults.headers.Authorization = `Bearer ${data.token}`;
        dispatch(signInSuccess(data.token, data.customer));
        window.location.href = `/order-detail/${product.id}`;
      } else {
        setErrorPayment(data.message);
      }
    } catch (err) {
      setErrorPayment('Pagamento não autorizado');
    }
    setLoadingForm(false);
  }

  async function handleCouponSubmit() {
    setErrorCoupon(null);
    if (values.coupon === '') {
      setErrorCoupon('Digite o código de desconto');
      return false;
    }
    setAmount(product.value);
    try {
      const { data, status } = await axios.post('payments/discount/coupon', {
        code: values.coupon,
        company_id: company.id,
      });

      if (status === 200) {
        const valueCoupon = product.value - Number(data.value);
        if (Number(valueCoupon) > Number(amount)) {
          setErrorCoupon(
            'Você possui um desconto maior que o fornecido pelo cupom'
          );
        } else {
          setAmount(valueCoupon);
        }
      } else {
        setErrorCoupon(data.message);
      }
    } catch (error) {
      setErrorCoupon('Cupom não encontrado');
    }
  }

  async function handleEmailDiscount(email) {
    try {
      let count = 0;
      if (email.indexOf('@') === -1) {
        count += 1;
      } else if (email.split('@')[1].indexOf('.') === -1) {
        count += 1;
      }

      if (count === 0) {
        const { status } = await axios.post('payments/discount/mail', {
          email,
        });
        if (status === 200 && Number(amount) > 3800) {
          setAmount(3800);
        }
      }
    } catch (error) {}
  }

  async function handleCardNumberDiscount(cardNumber) {
    try {
      let count = 0;
      if (cardNumber.length < 6) {
        count += 1;
      }

      if (count === 0) {
        const { status } = await axios.post('payments/discount/card', {
          cardNumber,
        });
        if (status === 200 && Number(amount) > 2300) {
          setAmount(2300);
        }
      }
    } catch (error) {}
  }

  async function handleSubmitLogin() {
    setErrors({
      email: '',
      password: '',
      name: '',
      cpf: '',
      phone: '',
      cardName: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    });
    setErrorPayment(null);
    setErrorLogin(null);
    const lock_errors = {
      email: '',
      password: '',
      name: '',
      cpf: '',
      phone: '',
      cardName: '',
      cardNumber: '',
      cardDate: '',
      cardCvv: '',
    };
    let count = 0;
    if (values.email.indexOf('@') === -1) {
      lock_errors.email = 'Preencha seu email';
      count += 1;
    } else if (values.email.split('@')[1].indexOf('.') === -1) {
      lock_errors.email = 'Preencha seu email';
      count += 1;
    }
    if (values.password === '') {
      lock_errors.password = 'Preencha sua senha';
      count += 1;
    }
    if (count > 0) {
      return setErrors(lock_errors);
    }

    setLoadingForm(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/customerSessions`,
        {
          email: values.email,
          password: values.password,
        }
      );
      axios.defaults.headers.Authorization = `Bearer ${data.token}`;
      dispatch(signInSuccess(data.token, data.customer));
      setCustomer(data.customer);

      setValues({
        ...values,
        name: data.customer.name,
        password: data.customer.password,
        email: data.customer.email,
        cpf: data.customer.cpf,
      });

      const { status } = await axios.post('payments/discount/mail', {
        email: values.email,
      });
      if (status === 200 && Number(amount) > 3800) {
        setAmount(3800);
      }
    } catch (err) {
      setErrorLogin('Email e senha inválidos');
    }
    return setLoadingForm(false);
  }

  function logoutCustomer() {
    setCustomer(false);
    setAccount(null);
    dispatch(signOut());
  }

  return (
    <Container>
      {hasCompany ? (
        <>
          <HeaderPrimary current="browser" />
          <div id="wrap">
            {loadingForm && (
              <div className="loadingForm__content">
                <div className="loadingForm">
                  <CircularProgress size="100px" className="mt-3" />
                  <p>Aguarde um momento...</p>
                </div>
              </div>
            )}
            {loading ? (
              <div className="loading">
                <CircularProgress size="100px" className="mt-3" />
              </div>
            ) : (
              <div>
                <div id="grid_2">
                  <form onSubmit={handleSubmit} id="action_section">
                    <h3>1. Detalhe do produto</h3>
                    <div className="plans">
                      <div className="item_plan">
                        <div className="description">
                          <div>
                            <h5>{product.title}</h5>
                            <p>{product.description}</p>
                          </div>
                        </div>
                        <div className="price">
                          <table>
                            <tbody>
                              <tr>
                                <td>Valor</td>
                                <td>R$ {(product.value / 100).toFixed(2)}</td>
                              </tr>
                              <tr>
                                <td>Assinante ELLE</td>
                                <td>R$ 38,00</td>
                              </tr>
                              <tr>
                                <td>Cliente Santander</td>
                                <td>R$ 23,00</td>
                              </tr>
                            </tbody>
                          </table>
                          <span>
                            * Validado pelo e-mail de assinante ou número de
                            cartão Santander.
                          </span>
                        </div>
                      </div>
                    </div>
                    <h3>2. Dados Pessoais</h3>
                    <div id="form_account" className="mb-5">
                      {customer ? (
                        <div className="accountCustomerLogin">
                          <p>Olá, {customer.name}.</p>
                          <button
                            type="button"
                            onClick={() => logoutCustomer()}
                            className="logout"
                          >
                            sair
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex justify-content-between mb-5">
                            <button
                              onClick={() => {
                                setAccount('login');
                              }}
                              type="button"
                              className={
                                account === 'login' ? 'mr-2' : 'mr-2 account'
                              }
                            >
                              Já sou cliente
                            </button>
                            <button
                              onClick={() => {
                                setAccount('new');
                              }}
                              type="button"
                              className={
                                account === 'new' ? 'ml-2' : 'ml-2 account'
                              }
                            >
                              Ainda não sou cliente
                            </button>
                          </div>
                          <div
                            className={account === 'login' ? 'show' : 'hide'}
                          >
                            {errorLogin && (
                              <div className="alert alert-danger mb-4">
                                {errorLogin}
                              </div>
                            )}
                            <div className="input-group col-100">
                              <TextField
                                variant="outlined"
                                label="SEU E-MAIL"
                                type="mail"
                                value={values.email}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    email: '',
                                  });
                                  setValues({
                                    ...values,
                                    email: e.target.value,
                                  });
                                }}
                                error={errors.email !== ''}
                              />
                            </div>
                            <div className="input-group col-100">
                              <TextField
                                variant="outlined"
                                type="password"
                                label="SUA SENHA"
                                value={values.password}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    password: '',
                                  });
                                  setValues({
                                    ...values,
                                    password: e.target.value,
                                  });
                                }}
                                error={errors.password !== ''}
                              />
                            </div>
                            <div className="input-group col-100">
                              <button
                                type="button"
                                onClick={() => handleSubmitLogin()}
                              >
                                Login
                              </button>
                            </div>
                          </div>
                          <div className={account === 'new' ? 'show' : 'hide'}>
                            <div className="input-group col-100">
                              <TextField
                                variant="outlined"
                                label="INFORME SEU NOME"
                                type="text"
                                value={values.name}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    name: '',
                                  });
                                  setValues({
                                    ...values,
                                    name: e.target.value,
                                  });
                                }}
                                error={errors.name !== ''}
                              />
                            </div>
                            <div className="input-group col-100">
                              <TextField
                                variant="outlined"
                                label="SEU E-MAIL"
                                type="mail"
                                value={values.email}
                                onBlur={(e) => {
                                  handleEmailDiscount(e.target.value);
                                }}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    email: '',
                                  });
                                  setValues({
                                    ...values,
                                    email: e.target.value,
                                  });
                                }}
                                error={errors.email !== ''}
                              />
                            </div>
                            <div className="col-100 d-flex input-group justify-content-between">
                              <TextField
                                variant="outlined"
                                type={typePassword}
                                label="SUA SENHA"
                                value={values.password}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    password: '',
                                  });
                                  setValues({
                                    ...values,
                                    password: e.target.value,
                                  });
                                }}
                                error={errors.password !== ''}
                                style={{
                                  width: '90%',
                                }}
                              />
                              <button
                                type="button"
                                style={{
                                  width: 'calc(10% - 10px)',
                                  margin: '0 0 0 10px',
                                  background: 'transparent',
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  setTypePassword(
                                    typePassword === 'password'
                                      ? 'text'
                                      : 'password'
                                  )
                                }
                              >
                                {typePassword === 'password' ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </button>
                            </div>
                            <div className="input-group col-50">
                              <TextField
                                variant="outlined"
                                label="CPF"
                                type="text"
                                value={values.cpf}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    cpf: '',
                                  });
                                  setValues({
                                    ...values,
                                    cpf: normalizeNumber(e.target.value),
                                  });
                                }}
                                error={errors.cpf !== ''}
                              />
                              <TextField
                                variant="outlined"
                                label="TELEFONE"
                                type="text"
                                value={values.phone}
                                onChange={(e) => {
                                  setErrors({
                                    ...errors,
                                    phone: '',
                                  });
                                  setValues({
                                    ...values,
                                    phone: normalizeNumber(e.target.value),
                                  });
                                }}
                                error={errors.phone !== ''}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <h3>3. Cupom de desconto</h3>
                    <div id="form_account">
                      {errorCoupon && (
                        <p className="alert alert-danger mb-4">{errorCoupon}</p>
                      )}
                      <div className="d-flex justify-content-between">
                        <TextField
                          variant="outlined"
                          type="text"
                          value={values.coupon}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              coupon: e.target.value,
                            });
                          }}
                          label="DIGITE SEU CUPOM"
                          style={{ width: '60%' }}
                        />
                        <button
                          className="btn"
                          style={{
                            width: '38%',
                            padding: '15px',
                            margin: '0 0 0 2%',
                          }}
                          disabled={loadingForm}
                          type="button"
                          onClick={() => handleCouponSubmit()}
                        >
                          Inserir cupom
                        </button>
                      </div>
                    </div>
                    <h3>4. Resumo</h3>
                    <div id="form_account">
                      <div className="resume">
                        <div>
                          <h5>{product.title}</h5>
                          <p>{product.description}</p>
                        </div>
                        <p className="price">R$ {(amount / 100).toFixed(2)}</p>
                      </div>
                    </div>
                    <h3>5. Pagamento</h3>
                    <div id="form_account">
                      {errorPayment && (
                        <p className="alert alert-danger mb-4">
                          {errorPayment}
                        </p>
                      )}
                      <div className="input-group col-100">
                        <TextField
                          variant="outlined"
                          label="NÚMERO DO CARTÃO"
                          type="text"
                          value={values.cardNumber}
                          onBlur={(e) => {
                            handleCardNumberDiscount(e.target.value);
                          }}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              cardNumber: '',
                            });
                            setValues({
                              ...values,
                              cardNumber: normalizeNumber(e.target.value),
                            });
                          }}
                          error={errors.cardNumber !== ''}
                        />
                      </div>
                      <div className="input-group col-100">
                        <TextField
                          variant="outlined"
                          label="NOME IMPRESSO NO CARTÃO"
                          type="text"
                          value={values.cardName}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              cardName: '',
                            });
                            setValues({
                              ...values,
                              cardName: e.target.value,
                            });
                          }}
                          error={errors.cardName !== ''}
                        />
                      </div>
                      <div className="input-group col-50">
                        <TextField
                          variant="outlined"
                          label="DATA DE VALIDADE (MM/AA)"
                          type="text"
                          value={values.cardDate}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              cardDate: '',
                            });
                            setValues({
                              ...values,
                              cardDate: normalizeDate(e.target.value),
                            });
                          }}
                          inputProps={{ maxLength: 5 }}
                          error={errors.cardDate !== ''}
                        />
                        <TextField
                          variant="outlined"
                          label="CÓDIGO DE SEGURANÇA (CVV)"
                          type="text"
                          value={values.cardCvv}
                          onChange={(e) => {
                            setErrors({
                              ...errors,
                              cardCvv: '',
                            });
                            setValues({
                              ...values,
                              cardCvv: normalizeNumber(e.target.value),
                            });
                          }}
                          inputProps={{ maxLength: 4 }}
                          error={errors.cardCvv !== ''}
                        />
                      </div>
                      <ReCAPTCHA
                        sitekey="6LdhoEsaAAAAADq9-OxGVHMevuKYyXQOZaulv7Zt"
                        size="invisible"
                        ref={reRef}
                      />
                      <button disabled={loadingForm}>Finalizar compra</button>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="loading">
          <CircularProgress size="100px" className="mt-3" />
        </div>
      )}
    </Container>
  );
}

export default Checkout;
